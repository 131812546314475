import 'shared/css/paf-bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.js';

import LoginArea from './js/login-area.js';
import LoginForm from './js/login-form.js';
import 'shared/css/login.css';

import PAF from 'shared/js/paf.js';
import * as loader from 'shared/js/loading-overlay.js';

class LoginPage {
  constructor({ loginRedirect, showRedirMsg }) {
    this.loginForm = new LoginForm({ loginRedirect, showRedirMsg });
    this.loginArea = new LoginArea();
  }

  redirect() {
    this.loginForm.redirect();
  }

  // here we add the container and mount the necessary pieces to the proper
  // container divs
  mount(mountSelector) {
    this.loginArea.mount(mountSelector, this.loginForm);
  }
}

// this runs at startup and gets everything going
$(() => {
  loader.addLoadingAnimation('body');
  const params = PAF.getUrlVars();

  const loginPage = new LoginPage({
    loginRedirect: params.redir,
    showRedirMsg: params.show_redir_msg,
  });
  // set storage
  // Wasn't sure the best way to do this, but this works -Doug
  PAF.Query('auth/logged_in', {}, true).then((loggedIn) => {
    if (loggedIn) {
      loginPage.redirect();
    } else {
      loginPage.mount('.paf-content-area');
    }
  });
});
